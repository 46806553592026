import { Button, DownloadIcon } from "@appia/ui-components"
import { ContractFile } from "@appia/api"

interface DownloadFileButtonProps {
  file: ContractFile
  onDownloadClick: (file: ContractFile) => void
}

const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({
  file,
  onDownloadClick,
}) => {
  return (
    <Button
      data-testid="file-download-button"
      label=""
      style="text"
      theme="night"
      onClick={() => onDownloadClick(file)}
      icon={{
        position: "left",
        icon: <DownloadIcon className="h-5 w-5 justify-end" />,
      }}
    />
  )
}

export default DownloadFileButton
